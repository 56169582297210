import Vue from 'vue'
import store from '../index'

const getDefaultState = () => {
  return {
    isAddEntitiesEnabled: true,
    componentKey: 1,
    physicalAddress: {},
    registeredAddress: {},
    registeredAddressBVDField: {},
    physicalAddressBVDField: {},
    country: [],
    addressTypeDetails: [],
    countrySubDivision: [],
    physicalSubDivision: { text: '', value: '' },
    regSubDivision: { text: '', value: '' },
    isBVDEvidenceTrigger: false,
    shouldOverride: false,
    nuts3: [],
    entityDetails: {
      sgaId: null,
      assignmentId: null,
      workType: null
    },
    entityDetailsForTab: {
      sgaId: null,
      assignmentId: null,
      workType: null
    },
    stepperForm: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getEntityDetails: (state) => {
      return state.entityDetails
    },
    getStepperForm: (state) => {
      return state.stepperForm
    }
  },
  actions: {
    getAddressDetails({ commit }, sgaId) {
      // Reset store
      commit('SET_REGISTERED_ADDRESS', {})
      commit('SET_PHYSICAL_ADDRESS', {})
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/dmp/address?sgaId=${sgaId}`)
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_REGISTERED_ADDRESS', response.data.data.registered)
                commit('SET_PHYSICAL_ADDRESS', response.data.data.domicile)
                resolve()
              }
            } else if (response.data.status === 204) {
              //  todo: action to be decided
              resolve()
            } else {
              // TODO: Async/Await the process
              resolve()
            }
            // commit('LOADING', false)
          })
          .catch((error) => {
            reject(error)
            // commit('LOADING', false)
          })
      })
    },

    async updateAddressDetails({ commit }, updateAddresses) {
      try {
        const { sgaId, addressDetails } = updateAddresses
        const response = await Vue.prototype.$http.put(
          `/dmp/address?sgaId=${sgaId}`,
          addressDetails
        )
        // Returned response for status of submission
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },

    getAddressType({ commit, state }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/dmp/address/addressType')
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('ADDRESS_TYPE', response.data.data)
                resolve()
              }
            }
            if (response.data.status === 204) {
              resolve()
              //  todo: action to be decided
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateComponentKey({ commit }, newKey) {
      commit('SET_COMPONENT_KEY', newKey)
    },
    updateEntitiesStatus({ commit }, { entityStatus, entityDetails }) {
      commit('SET_ENTITIES_STATUS', entityStatus)
      commit('SET_ENTITY_DETAILS', entityDetails)
    },

    updateRegisteredAddress({ commit }, formData) {
      commit('SET_REGISTERED_ADDRESS', formData)
    },
    updatePhysicalAddress({ commit }, formData) {
      commit('SET_PHYSICAL_ADDRESS', formData)
    },

    getCountryList({ commit, state }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/dmp/address/country')
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_COUNTRY', response.data.data)
                resolve()
              }
            }
            if (response.data.status === 204) {
              resolve()

              //  todo: action to be decided
            }
            // commit('LOADING', false)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getStateList({ commit }, countryId) {
      return new Promise((resolve, reject) => {
        commit('SET_STATE', [])
        Vue.prototype.$http
          .get(`/dmp/address/state?countryId=${countryId}`)
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_STATE', response.data.data)
              }
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    // Vuex action
    async copyFieldToAddress({ commit, state }, obj) {
      const { data, field, shouldOverride } = obj
      commit('SHOULD_OVERRIDE', shouldOverride)
      const bvdRegister = {}
      const bvdDomicile = {}
      for (const key in data) {
        // Check if the property exists in registeredAddress or physicalAddress
        if (
          (key in state.registeredAddress || key in state.physicalAddress) &&
          Object.prototype.hasOwnProperty.call(data, key)
        ) {
          if (field === 'registered' && key in state.registeredAddress) {
            bvdRegister[key] = data[key]
            commit('SET_REGISTERED_ADDRESS_FIELD', bvdRegister)
          } else if (field === 'physical' && key in state.physicalAddress) {
            bvdDomicile[key] = data[key]
            commit('SET_PHYSICAL_ADDRESS_FIELD', bvdDomicile)
          } else {
            if (key in state.registeredAddress) {
              bvdRegister[key] = data[key]
            }
            if (key in state.physicalAddress) {
              bvdDomicile[key] = data[key]
            }
            commit('SET_PHYSICAL_ADDRESS_FIELD', bvdDomicile)
            commit('SET_REGISTERED_ADDRESS_FIELD', bvdRegister)
          }
        }
      }
    },

    // Define other actions as needed
    // Print updated this.registerAddress

    async updateFullAddress({ commit }, entityFlagObj) {
      const { sgaId, fullAddress } = entityFlagObj
      const reqData = {
        sgaId: sgaId,
        address: fullAddress
      }

      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/address/update-full-address',
          reqData
        )

        if (response?.data && response.data?.status === 200) {
          // return true if updated successfully
          return true
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    getNuts3List({ commit }, countryId) {
      commit('SET_NUTS3', [])

      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/dmp/address/nuts3?countryId=${countryId}`)
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_NUTS3', response.data.data)
              }
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    updateStateList({ commit }, stateList) {
      commit('SET_STATE', stateList)
    },

    updateNuts3List({ commit }, nuts3List) {
      commit('SET_NUTS3', nuts3List)
    },

    updateStepperForm({ commit }, stepperForm) {
      commit('SET_STEPPER_FORM', stepperForm)
    },

    resetAddressDetails({ commit }) {
      commit('SET_REGISTERED_ADDRESS', {})
      commit('SET_PHYSICAL_ADDRESS', {})
    },

    resetFormsDetails({ commit }) {
      commit('RESET_FORM_DETAILS')
    }
  },
  mutations: {
    SET_COMPONENT_KEY(state, newKey) {
      state.componentKey = newKey
    },
    SHOULD_OVERRIDE(state, shouldOverride) {
      state.shouldOverride = shouldOverride
    },
    SET_ENTITIES_STATUS(state, entityStatus) {
      state.isAddEntitiesEnabled = entityStatus
    },
    SET_REGISTERED_ADDRESS(state, formData) {
      state.registeredAddress = formData
    },
    SET_PHYSICAL_ADDRESS(state, formData) {
      state.physicalAddress = formData
    },
    SET_REGISTERED_ADDRESS_FIELD(state, data) {
      state.registeredAddressBVDField = data
    },
    SET_PHYSICAL_ADDRESS_FIELD(state, data) {
      state.physicalAddressBVDField = data
    },

    SET_COUNTRY(state, countryList) {
      state.country = countryList.map((countryObj) => {
        // Assign new key
        countryObj.value = countryObj.id
        countryObj.text = `${countryObj.countryCode}-${countryObj.name}`

        // Delete old key
        delete countryObj.id
        delete countryObj.name

        return countryObj
      })
    },
    ADDRESS_TYPE(state, addressTypeDetails) {
      state.addressTypeDetails = addressTypeDetails
    },

    SET_STATE(state, stateList) {
      state.countrySubDivision = stateList.map((stateObj) => {
        // Assign new key
        stateObj.value = stateObj.id
        stateObj.text = `${stateObj.subDivisionCode}-${stateObj.name}`
        stateObj.stateCode = stateObj.subDivisionCode.split('-')[1]

        // Delete old key
        delete stateObj.id
        delete stateObj.name

        return stateObj
      })
    },

    SET_NUTS3(state, nuts3List) {
      state.nuts3 = nuts3List.map((nuts3Obj) => {
        // Assign new key
        nuts3Obj.value = nuts3Obj.id
        nuts3Obj.text = `${nuts3Obj.id}-${nuts3Obj.name}`

        // Delete old key
        delete nuts3Obj.id
        delete nuts3Obj.name

        return nuts3Obj
      })
    },
    ENTITY_SUB_TYPE_VALUE(state, entitySubType) {
      state.entitySubType = entitySubType
    },

    SET_ENTITY_TYPE(state, entityTypeList) {
      state.entityType = entityTypeList.map((entityTypeObj) => {
        // Assign new key

        entityTypeObj.value = entityTypeObj.id
        entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.name}`

        // Delete old key
        delete entityTypeObj.id
        delete entityTypeObj.name

        return entityTypeObj
      })
    },

    SET_ENTITY_SUBTYPE(state, entitySubTypeList) {
      state.entitySubType = entitySubTypeList.map((entitySubTypeObj) => {
        // Assign new key
        entitySubTypeObj.value = entitySubTypeObj.id
        entitySubTypeObj.text = `${entitySubTypeObj.id}-${entitySubTypeObj.name}`

        // Delete old key
        delete entitySubTypeObj.id
        delete entitySubTypeObj.name

        return entitySubTypeObj
      })
    },

    SET_ENTITY_DETAILS(state, { sgaId, assignmentId, workType, isCAWorkType }) {

      state.entityDetails.sgaId = sgaId || state.entityDetails.sgaId
      state.entityDetails.assignmentId =
        assignmentId || state.entityDetails.assignmentId
      state.entityDetails.workType = workType || state.entityDetails.workType
      state.entityDetails.isCAWorkType = isCAWorkType || false
    },

    SET_ENTITY_DETAILS_FOR_TAB(
      state,
      { sgaId, assignmentId, workType, isCAWorkType }
    ) {

      state.entityDetailsForTab.sgaId = sgaId || state.entityDetailsForTab.sgaId
      state.entityDetailsForTab.assignmentId =
        assignmentId || state.entityDetailsForTab.assignmentId
      state.entityDetailsForTab.workType =
        workType || state.entityDetailsForTab.workType
      state.entityDetailsForTab.isCAWorkType = isCAWorkType || false
    },

    SET_STEPPER_FORM(state, stepperForm) {
      state.stepperForm = stepperForm
    },

    RESET_FORM_DETAILS(state) {
      // Form - storeName
      // Basic Details - basicDetails
      store.state.basicDetails.basicDetails = {}

      // Address Details - dmp
      state.physicalAddress = {}
      state.registeredAddress = {}

      // Industry Details - industry
      store.state.industry.industryDetail = {}

      // Identifiers - identifiers
      store.state.identifiers.identifiersDetails = {}

      // AnaCredit Details - anaCredit
      store.state.anaCredit.anaCreditDetails = {}

      // 2052A Details - details2052a
      store.state.details2052a.details2052a = null

      // Relationships - relationship
      store.state.relationship.relationshipData = []
    }
  }
}
